<template>
<div class="mainpage">
    
    <div class="fullver">
        <nav class="navbar navbar-expand-lg navbar-light fixed-top" style="background: #fff;">
            <div class="container">
                <div class="d-flex justify-content-between w-100 my-3 text-bg-light navbar-mobile">
                    <div class="d-flex">
                        <a class="navbar-brand fw-bold my-auto tapwillimg pe-2 pe-sm-3 me-0" href="/#" style="font-family: 'Unbounded', sans-serif;">TapWill</a>
                        <div class="stats d-flex flex-column justify-content-start ps-2 ps-sm-3">
                            <p class="my-auto navbar-brand-text">Статистика и аналитика товаров <br> <span class = 'Wildberries'> Wildberries </span>, <span class = 'Ozon'> Ozon </span> и <span style="color: #1E1E1E;">Яндекс.Маркет</span> </p>
                        </div>
                    </div>
                    
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon d-flex"></span>
                    </button>
                </div>
                <div class="header_right ms-lg-auto ms-2">
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav flex-row my-3">
                        <!-- <li class="nav-item my-auto">
                        <a class="nav-link fw-semibold" href="#about_us">О сервисе</a>
                        </li>
                        <li class="nav-item my-auto">
                        <a class="nav-link fw-semibold" href="#tariff">Подписка</a>
                        </li>
                        <li class="nav-item my-auto">
                        <a class="nav-link fw-semibold" href="#faq">FAQ</a>
                        </li> -->
                        <li class="nav-item my-auto ">
                            <a class="nav-link fw-semibold d-flex" href="/auth">
                            <img src="../assets/images/main/iconamoon_enter-fill.png" alt="">
                            <span class="my-auto">Войти</span> 
                        </a>
                        </li>
                        <li class="nav-item my-auto">
                        <a class="nav-link btn-gradient d-flex" href="/reg" style="color: #fff;">Зарегистрироваться</a> 
                        </li>
                    </ul>
                </div>
              </div>
            </div>
        </nav>

    <div class="account-pages pt-2 py-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xxl-6 col-lg-8">
        <div class="reg-form">
          <div class="p-4 main-block-2-block w-100">
            <div class="text-center">
              <h4 class="reg-form-title mt-0">Войти в аккаунт</h4>
              <p class="mb-4">Введите свой email и пароль для авторизации в сервисе</p>
            </div>
            <form @submit.prevent="sendForm" id="signIn">
              <div class="mb-3">
                <label for="login" class="form-label">Email</label>
                <input class="form-control" v-model="form_data.mail" name="login" type="email" id="login" placeholder="ivanov@yandex.ru" required>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Пароль</label>
                <div class="input-group input-group-merge">
                  <input type="password" name="password" v-model="form_data.password" id="password" class="form-control" placeholder="Введите пароль" required>
                  
                </div>
              </div>
              <div class="mb-3 mt-4 text-center">
                <button class="btn-gradient w-100" name="signIn" type="submit"> Войти
                </button>
                <div class="small mt-4 text-center"><a href="/forgot-password"  style="color: #8C43CF; text-decoration: none; font-weight: 600;">Забыли пароль?</a></div>

              </div>
              
            </form>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 text-center">
            <p style="font-weight: 600; color: #1E1E1E">
              У вас нет аккаунта? <a href="/reg" class="ms-1" style="color: #8C43CF; text-decoration: none;">Зарегистрируйтесь</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
</div>
</template>


<style lang="scss" scoped>
  @import '../assets/style/mainpagenew.scss';
</style>

<script>
import axios from 'axios';

export default{
  data(){
    return{
      form_data: {
        mail: '',
        password: ''
      }
      
    }
  },

  methods: {
    sendForm(){
      axios.post('https://tapwill.ru/api/auth.php', this.form_data).then((response)=>{
        if(response.status == 200){
          if(response.data == 0){
            alert('Пользователь не найден')
          }
          else{
            this.$store.commit('authUser', response.data)
            window.location.href='/user-stores'
          }
        }
        else{
          alert('Произошла ошибка! Попробуйте позднее')
        }
      })
    }
  },
  created(){
    if(this.$store.state.user == true){
      window.location.href = '/dashboard'
    }
  }
}
</script>