<template>
    <div class="main-menu" v-if="userUrl !== '/' && userUrl !== '/auth' && userUrl !== '/reg' && userUrl !== '/forgot-password'">

    <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-lg-top fixed-top col-lg-2 col-12 pt-3 pb-3 pb-lg-0 px-4 px-lg-0">
         <a href="/dashboard" class="navbar-brand mb-lg-5">TapWill</a>
            <button class="navbar-toggler" style="border: none;"ype="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
          <div class="collapse navbar-collapse flex-column mt-lg-0 mt-4" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 flex-column">
              <li class="nav-item"><a class="nav-link" :class="{'active': userUrl == '/dashboard'}" href="/dashboard"><img src="../assets/images/icons/dashboard.svg" class="nav-link-image me-1" alt="">Дашборд</a></li>
                  <li class="nav-item"><a href="/orders" :class="{'active': userUrl == '/orders'}" class="nav-link"><img src="../assets/images/icons/orders.svg" class="nav-link-image me-1" alt="">Заказы</a></li>
                  <li class="nav-item"><a href="/itemsinfo" :class="{'active': userUrl == '/itemsinfo'}" class="nav-link"><img src="../assets/images/icons/itemsinfo.svg" class="nav-link-image me-1" alt="">PnL по товарам (сводка)</a></li>
                  <li class="nav-item"><a href="/abc" :class="{'active': userUrl == '/abc'}" class="nav-link"><img src="../assets/images/icons/abc.svg" class="nav-link-image me-1" alt="">ABC анализ по товарам</a></li>
                  <li class="nav-item"><a href="/advert" :class="{'active': userUrl == '/advert' || userUrl == '/advert/stat' || userUrl == '/advert/list'}" class="nav-link"><img src="../assets/images/icons/adv.svg" class="nav-link-image me-1" alt="">Реклама</a></li>
                  <li class="nav-item"><a href="/geography" :class="{'active': userUrl == '/geography'}" class="nav-link"><img src="../assets/images/icons/geography.svg" class="nav-link-image me-1" alt="">География заказов и продаж</a></li>
                  <li class="nav-item"><a href="/profit" :class="{'active': userUrl == '/profit'}" class="nav-link"><img src="../assets/images/icons/profit.svg" class="nav-link-image me-1" alt="">Сводка по прибыли</a></li>
                  <li class="nav-item"><a href="/restitems" :class="{'active': userUrl == '/restitems'}" class="nav-link"><img src="../assets/images/icons/items.svg" class="nav-link-image me-1" alt="">Остатки и оборачиваемость</a></li>
                  <li class="nav-item"><a href="/actions_ozon" :class="{'active': userUrl == '/actions_ozon'}" class="nav-link"><img src="../assets/images/icons/actions_ozon.svg" class="nav-link-image me-1" alt="">Акции Ozon</a></li>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li class="nav-item"><a href="/profile-edit" :class="{'active': userUrl == '/profile-edit'}" class="nav-link"><img src="../assets/images/icons/profile.svg" class="nav-link-image me-1" alt="">Редактировать профиль</a></li>
                  <li class="nav-item"><a href="/user-stores" :class="{'active': userUrl == '/user-stores'}" class="nav-link"><img src="../assets/images/icons/stores.svg" class="nav-link-image me-1" alt="">Магазины</a></li>
                  <li class="nav-item"><a href="/uploadcost" :class="{'active': userUrl == '/uploadcost'}" class="nav-link"><img src="../assets/images/icons/cost.svg" class="nav-link-image me-1" alt="">Себестоимость</a></li>
                  <li class="nav-item"><a href="/tariff" :class="{'active': userUrl == '/tariff'}" class="nav-link"><img src="../assets/images/icons/tariff.svg" class="nav-link-image me-1" alt="">Подписка и платежи</a></li>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li class="nav-item"><a href="/auth" @click="$store.commit('exitUser')" class="nav-link"><img src="../assets/images/icons/exit.svg" class="nav-link-image me-1" alt="">Выйти</a></li>
            </ul>
          </div>
          
      </nav>
      <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
        <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
        </symbol>
        <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
        </symbol>
        <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
        </symbol>
        <symbol id="tariffCheck" viewBox="0 0 16 16">
          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"></path>
        </symbol>
        <symbol id="tariffCheckFailure" viewBox="0 0 16 16">
          <path d="M5.736 0.6H7.064V11.24H5.736z" transform="rotate(45.001 4.5 9)"></path>
          <path d="M5.736 0.6H7.064V11.24H5.736z" transform="rotate(135.003 6.8 7.3)"></path>
        </symbol>
      </svg>

      </div>
    
</template>

<script>
import axios from 'axios'
export default{
  data(){
    return{
      userUrl: window.location.pathname
    }
  },
  created(){
    axios.post('https://test.tapwill.ru/api/check_user.php', {mail: this.$store.state.user_info.mail, user_id: this.$store.state.user_info.id}).then((response)=>{
      this.$store.state.user_info.check = response.data
      console.log(response.data)
    })
  }
}
</script>