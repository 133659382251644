<template>
<div class="mainpage">
    
    <div class="fullver">
        <nav class="navbar navbar-expand-lg navbar-light fixed-top" style="background: #fff;">
            <div class="container">
                <div class="d-flex justify-content-between w-100 my-3 text-bg-light navbar-mobile">
                    <div class="d-flex">
                        <a class="navbar-brand fw-bold my-auto tapwillimg pe-2 pe-sm-3 me-0" href="/#">TapWill</a>
                        <div class="stats d-flex flex-column justify-content-start ps-2 ps-sm-3">
                            <p class="my-auto navbar-brand-text">Статистика и аналитика товаров <br> <span class = 'Wildberries'> Wildberries </span>, <span class = 'Ozon'> Ozon </span> и <span style="color: #1E1E1E;">Яндекс.Маркет</span> </p>
                        </div>
                    </div>
                    
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon d-flex"></span>
                    </button>
                </div>
                <div class="header_right ms-lg-auto ms-2">
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav flex-row my-3">
                        <!-- <li class="nav-item my-auto">
                        <a class="nav-link fw-semibold" href="#about_us">О сервисе</a>
                        </li>
                        <li class="nav-item my-auto">
                        <a class="nav-link fw-semibold" href="#tariff">Подписка</a>
                        </li>
                        <li class="nav-item my-auto">
                        <a class="nav-link fw-semibold" href="#faq">FAQ</a>
                        </li> -->
                        <li class="nav-item my-auto ">
                            <a class="nav-link fw-semibold d-flex" href="/auth">
                            <img src="../assets/images/main/iconamoon_enter-fill.png" alt="">
                            <span class="my-auto">Войти</span> 
                        </a>
                        </li>
                        <li class="nav-item my-auto">
                        <a class="nav-link btn-gradient d-flex" href="/reg" style="color: #fff;">Зарегистрироваться</a> 
                        </li>
                    </ul>
                </div>
              </div>
            </div>
        </nav>

        <div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xxl-6 col-lg-8">
        <div class="reg-form">
          <div class="p-4 main-block-2-block w-100">
            <div class="text-center">
              <h4 class="text-center mt-0">Забыли пароль?</h4>
              <p class="mb-4">Введите свой email, указанный при регистрации. Вышлем ссылку для сброса пароля.</p>
            </div>
            <form id="forgotPassword" v-if="checkUserCode" @submit.prevent="changePassword">
              <div class="mb-3">
                <label for="fp_password" class="form-label">Новый пароль</label>
                <input class="form-control" type="password" id="fp_password" v-model="password" placeholder="Придумайте новый пароль" minlength="5" maxlength="30" required>
              </div>
              <div class="mb-3 text-center">
                <div class="d-grid gap-2">
                  <button class="btn btn-gradient"> Изменить пароль
                  </button>
                </div>
              </div>
            </form>
            <form id="forgotPassword" v-if="codeSended && !checkUserCode" @submit.prevent="checkCode">
              <div class="mb-3">
                <label for="fp_code" class="form-label">Код из письма</label>
                <input class="form-control" type="text" id="fp_code" v-model="userCode" placeholder="Ваш секретный код" required>
              </div>
              <div class="mb-3 text-center">
                <div class="d-grid gap-2">
                  <button class="btn btn-gradient"> Подтвердить
                  </button>
                </div>
              </div>
            </form>
            <form id="forgotPassword" v-if="!codeSended && !checkUserCode" @submit.prevent="sendCode">
              <div class="mb-3">
                <label for="fp_mail" class="form-label">Email</label>
                <input class="form-control" type="email" id="fp_mail" v-model="mail" placeholder="ivanov@yandex.ru" required>
              </div>
              <div class="mb-3 text-center">
                <div class="d-grid gap-2">
                  <button class="btn btn-gradient"> Восстановить пароль
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- <div class="row mt-3">
          <div class="col-12 text-center">
            <p class=""><a href="/auth" class="ms-1">Авторизоваться</a></p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
    </div>

</div>
</template>

<style lang="scss" scoped>
  @import '../assets/style/mainpagenew.scss';
</style>

<script>
import axios from 'axios'
export default{
  data(){
    return{
      mail: '',
      codeSended: false,
      userCode: '',
      checkUserCode: false,
      userId: 0,
      password: ''
    }
  },
  methods: {
    sendCode(){
      axios.post('https://test.tapwill.ru/api/send_code.php', {mail: this.mail}).then((response)=>{
        if(response.data !== 0){
          this.codeSended = true
        }
        else{
          alert('Неверный email')
        }
      })
    },
    checkCode(){
      axios.post('https://test.tapwill.ru/api/check_code.php', {mail: this.mail, code: this.userCode}).then((response)=>{
        if(response.data !== 0 && response.status == 200){
          this.checkUserCode = true
          this.userId = response.data
          console.log(this.userId)
        }
        else{
          alert('Неверный код!')
          window.location.reload()
        }
      })
    },
    changePassword(){
        axios.post('https://test.tapwill.ru/api/update_profile.php', {action: 'update_password', id: this.userId, password: this.password})
          .then((response)=>{
            if(response.data == 1 && response.status == 200){
              
              alert('Успешно!');
              window.location.href = '/auth'
            }
            else{
              alert('Произошла ошибка! Попробуйте позднее');
            }
          })
    }
    }
  }
</script>