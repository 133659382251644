<template>
    <div class="container-fluid">

      <div class="alert alert-warning" role="alert">
        <p class="mb-0" style="font-size: 0.9em;">Нашли критическую ошибку в работе сервиса? Напишите на почту <a href="mailto:mail@tapwill.ru">mail@tapwill.ru</a> или в бот <a href="https://t.me/tapwill_support_bot">@tapwill_support_bot</a>. Мы подарим вам дополнительно 5 дней подписки. </p>
      </div>
      
  <div class="head-block row row-cols-1 row-cols-lg-2 justify-content-between">
    <div class="col content-title">
      <h1>Рекламные кампании</h1></div>
  </div>
  
  <div class="content-block">
    <nav class="nav nav-tabs mb-3 mt-5">
      <li class="nav-item">
        <a href="/advert/stat" class="nav-link">Статистика по рекламе</a>
      </li>
      <li class="nav-item">
        <a href="/advert/list" class="nav-link active">Список рекламных кампаний</a>
      </li>
    </nav>
   </div>
  <div class="content-block">
    <div class="accordion d-lg-none" id="filterOpen">
            <div class="accordion-item border-0">
                <h2 class="accordion-header">
                <button class="accordion-button collapsed ps-0" style="width: fit-content;" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-controls="collapseOne">
                   <span class="pe-3">Показать параметры</span>
                 </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#filterOpen">
                <div class="accordion-body">

                  <div class="pageFilter">
      <div class="js-pageFilter__form">
        <div class="row row-cols-lg-auto align-items-end">
              <!-- <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Магазин</label>
                <select class="form-select" v-model="filter.store" >
                  <option value="">Не выбрано</option>
                  <option v-for="item in stores">{{ item.title }}</option>
                </select>
              </div> -->
              <div class="dropdown col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                  <label class="form-label">Магазин</label>
                  <button class="dropdown-select-button" data-bs-toggle="dropdown">
                    <span v-if="filter.store.length == 0">Выберите магазины </span v-else><span>{{ filter.store.join(', ') }}</span>&nbsp;&nbsp;&nbsp;
                  </button>

                  <ul class="dropdown-menu dropdown-select-list">
                    <li v-for="item in stores">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" :value="item.title" v-model="filter.store" :id="`store_${item.title}`">
                        <label class="form-check-label" :for="`store_${item.title}`">
                          {{ item.title }}
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Маркетплейс</label>
                <select class="form-select" v-model="filter.marketplace" >
                  <option value="">Не выбрано</option>
                  <option>wildberries</option>
                  <option>ozon</option>
<option>yandex</option>
                </select>
              </div>
              <!-- <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Статус</label>
                <select class="form-select" v-model="filter.status">
                  <option value="">Не выбрано</option>
                  <option value="-1">В процессе удаления</option>
                  <option value="4">Готова к запуску</option>
                  <option value="7">Завершена</option>
                  <option value="8">Отказался</option>
                  <option value="9">Идут показы</option>
                  <option value="11">На паузе</option>
                </select>
              </div> -->
              <div class="col-md js-pageFilter__text mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label class="form-label">Номер кампании</label>
                <input type="text" class="form-control" v-model="filter.advertId" autocomplete="off" >
              </div>
              <div class="col-md js-pageFilter__text mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label class="form-label">Название кампании</label>
                <input type="text" class="form-control" v-model="filter.name" autocomplete="off" >
              </div>
          <div class="col-md mb-3">
            <div class="btn-toolbar">
              <div class="btn-group me-2">
                <button class="btn btn-primary" @click="applyFilter">Применить</button>
              </div>
              <div class="btn-group"><a href="/advert/list" class="btn btn-outline-secondary">Сбросить</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>

                  </div>
                  </div>
                  </div>
                  </div>

    <div class="pageFilter d-lg-block d-none">
      <div class="js-pageFilter__form">
        <div class="row row-cols-lg-auto align-items-end">
              <!-- <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Магазин</label>
                <select class="form-select" v-model="filter.store" >
                  <option value="">Не выбрано</option>
                  <option v-for="item in stores">{{ item.title }}</option>
                </select>
              </div> -->
              <div class="dropdown col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                  <label class="form-label">Магазин</label>
                  <button class="dropdown-select-button" data-bs-toggle="dropdown">
                    <span v-if="filter.store.length == 0">Выберите магазины </span v-else><span>{{ filter.store.join(', ') }}</span>&nbsp;&nbsp;&nbsp;
                  </button>

                  <ul class="dropdown-menu dropdown-select-list">
                    <li v-for="item in stores">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" :value="item.title" v-model="filter.store" :id="`store_${item.title}`">
                        <label class="form-check-label" :for="`store_${item.title}`">
                          {{ item.title }}
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Маркетплейс</label>
                <select class="form-select" v-model="filter.marketplace" >
                  <option value="">Не выбрано</option>
                  <option>wildberries</option>
                  <option>ozon</option>
<option>yandex</option>
                </select>
              </div>
              <!-- <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Статус</label>
                <select class="form-select" v-model="filter.status">
                  <option value="">Не выбрано</option>
                  <option value="-1">В процессе удаления</option>
                  <option value="4">Готова к запуску</option>
                  <option value="7">Завершена</option>
                  <option value="8">Отказался</option>
                  <option value="9">Идут показы</option>
                  <option value="11">На паузе</option>
                </select>
              </div> -->
              <div class="col-md js-pageFilter__text mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label class="form-label">Номер кампании</label>
                <input type="text" class="form-control" v-model="filter.advertId" autocomplete="off" >
              </div>
              <div class="col-md js-pageFilter__text mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label class="form-label">Название кампании</label>
                <input type="text" class="form-control" v-model="filter.name" autocomplete="off" >
              </div>
          <div class="col-md mb-3">
            <div class="btn-toolbar">
              <div class="btn-group me-2">
                <button class="btn btn-primary" @click="applyFilter">Применить</button>
              </div>
              <div class="btn-group"><a href="/advert/list" class="btn btn-outline-secondary">Сбросить</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 2">
            <p>У вас не добавлено ни одного магазина, загрузите свой первый магазин в разделе "<a href="/user-stores">Магазины</a>" </p>
          </div>

          <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 1">
            <p>У вас нет активной подписки, вы можете продлить подписку в разделе "<a href="/tariff">Подписка и платежи</a>" </p>
          </div>

  <div v-if="$store.state.user_info.check == 0">
  <div class="modal-loader d-flex justify-content-center" v-if="loading">
    <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
  </div>
  <div class="content-block" v-else>
    <button class="btn btn-primary my-3"><json-excel
            :fetch="loadJsonData"
            type="xlsx"
            name="report.xls"
            :export-fields = exportFields
          >
          <div class="modal-loader mt-0 d-flex justify-content-center" v-if="json_data_loading">
            <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
          </div>
            <span v-else>Экспорт в Excel</span>
          </json-excel></button>
    <div class="table">
    <table class="custom-table d-sm-block d-none">
      <thead>
        <tr>
          <td>Номер</td>
          <td>Название</td>
          <td>Тип</td>
          <td>Статус</td>
          <td>Магазин</td>
          <td>Дата создания</td>
          <td>Дата изменения</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" class="table-light">
          <td><a :href="'/advert/'+item.advertId">{{ item.advertId }}</a></td>
          <td>{{ item.name }}</td>
          <td>{{ item.type }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.store }}</td>
          <td>{{ item.createTime }}</td>
          <td>{{ item.changeTime }}</td>
        </tr>
      </tbody>
    </table>

          <table class="custom-table d-sm-none table-light">
            <thead>
              <tr>
                <td>Номер</td>
                <td>Название</td>
                <td>Магазин</td>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in items">
                
                <td>
                  <a :href="'/advert/'+item.advertId">{{ item.advertId }}</a>
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.store }}</td>
              </tr>
            </tbody>
          </table>

    </div>
  </div>
</div>
</div>
</template>

<script>
import axios from 'axios'
import JsonExcel from "vue-json-excel3"

export default{
  components: {
    JsonExcel
  },
  data(){
    return {
      items: [],
      page: 0,
      filter: {
        store: [],
        advertId: '',
        name: '',
        status: '',
        marketplace: ''
      },
      stores: [],
      brands: [],
      loading: true,
      exportFields: {
        "Номер": 'advertId',
        "Название": 'name',
        "Тип": 'type',
        "Статус": "status",
        "Магазин": "store",
        "Дата создания": "createTime",
        "Дата изменения": "changeTime"
      },
      loadJsonData: async ()=>{
        this.json_data_loading = true
        let post_data = {
        store: this.filter.store,
        advertId: this.filter.advertId,
        name: this.filter.name,
        status: this.filter.status,
        marketplace: this.filter.marketplace,
        user_id: this.$store.state.user_info.id
      }

        const response = await axios.post('https://test.tapwill.ru/api/get_adv_campaigns.php', post_data)
          this.json_data_loading = false
          return response.data
        
      },
      json_data_loading: false
    }
  },
  methods: {
    loadData(){
      this.loading = true
      let post_data = {
        store: this.filter.store,
        advertId: this.filter.advertId,
        name: this.filter.name,
        status: this.filter.status,
        marketplace: this.filter.marketplace,
        user_id: this.$store.state.user_info.id
      }

      axios.post('https://test.tapwill.ru/api/get_adv_campaigns.php', post_data).then((response)=>{
        this.items = response.data
        console.log(this.items)
        this.loading = false
    })
    },
    applyFilter(){
      this.loadData()
    }
  },
  created(){
    this.loadData()
    axios.post('https://test.tapwill.ru/api/get_store.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
      this.stores = response.data
      console.log(this.stores)
    })
  }
}
</script>