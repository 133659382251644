<template>
    <!-- <div class="main-footer" v-if="userUrl !== '/reg' && userUrl !== '/auth'">
        <footer class="footer">
            <div class="container">
            <div class="row">
                <div class="col text-center">
                2024 © TapWill
                <a href="https://test.tapwill.ru/docs/politics.pdf" target="_blank" class="d-block mt-2" style="font-size: 0.9em; color: #000; opacity: 0.8;">Политика конфиденциальности</a>
                <a href="https://test.tapwill.ru/docs/offer.pdf" target="_blank" class="d-block mt-1" style="font-size: 0.9em; color: #000; opacity: 0.8;">Публичная офферта</a>
                </div>
            </div>
            </div>
        </footer>
    </div> -->

    <footer>
        <div class="main-footer px-3 px-sm-5">
            <div class="footer-main-block">
                <div class="row">
                    <div class="col-xl-6 col-12 main-footer-navigation">
                        <div class="d-flex">
                            <b class="my-auto tapwillimg pe-3 me-0" href="#">TapWill</b>
                            <div class="stats d-flex flex-column justify-content-start ps-3">
                                <p class="my-auto footer-mini-text">Статистика и аналитика товаров <br> <span class = 'Wildberries'> Wildberries </span>, <span class = 'Ozon'> Ozon </span> и <span>Яндекс.Маркет</span> </p>
                            </div>
                        </div>

                        <div class="footer-navigation">
                            <div class="row">
                                <div class="col-xl-6 col-12">
                                    <p class="footer-navigation footer-mini-text ms-3 ms-sm-0">Навигация</p>

                                    <div class="d-sm-flex d-lg-block footer-navigation-links ms-3 ms-sm-0">
                                        <div>
                                            <a href="/#" class="footer-link mt-xl-5">Главная</a>
                                            <a href="/#for_whom" class="footer-link">Для кого TapWill</a>
                                            <a href="/#benefits" class="footer-link">Преимущества</a>
                                            <a href="/#oportunities" class="footer-link">Возможности сервиса</a>
                                            <a href="/#get_file" class="footer-link">Получить файл</a>
                                        </div>
                                        <div>
                                            <a href="/#prices" class="footer-link">Стоимость подписки</a>
                                            <a href="/#reviews" class="footer-link">Отзывы о нас</a>
                                            <a href="/#faq" class="footer-link">Частые вопросы</a>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-xl-6 col-12 ms-3 ms-sm-0">
                                    <p class="footer-navigation footer-mini-text">Контакты</p>

                                    <a href="#" class="footer-link mt-3 mt-xl-5"><img src="../assets/images/footer_icon_1.svg" class="me-2 me-xl-3" alt="">+ 971 504-915-343</a>
                                    <a href="mailto:mail@tapwill.ru" class="footer-link"><img src="../assets/images/footer_icon_2.svg" class="me-2 me-xl-3" alt=""> mail@tapwill.ru </a>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-6 col-12 main-footer-form">
                        <h2>Остались <br class="d-xl-block d-none"> вопросы?</h2>
                        <p class="pb-4 footer-desc">Заполните форму или свяжитесь с нами в одном <br class="d-none d-lg-block"> из мессенджеров — ответим на ваши вопросы и расскажем <br> подробнее о нашем сервисе.</p>

                        <form class="footer-form mt-3 mt-xl-5">
                            <div class="footer-form-field d-flex mb-4">
                                <label class="me-3 my-auto">Имя</label>
                                <input type="text" id="footer-name">
                            </div>
                            <div class="footer-form-field d-flex mb-4">
                                <label class="me-3 my-auto">Тел.</label>
                                <input type="text" id="footer-name" placeholder="+7 (999) 999 99-99">
                            </div>
                            <button class="btn-white">Свяжитесь со мной</button>
                        </form>
                    </div>
                </div>
            </div>

            <div class="footer-bottom-block">
                <div class="d-xl-flex justify-content-around">
                    <div class="d-xl-flex">
                        <a href="/docs/politics.pdf" target="_blank" class="me-3 me-xl-5">Политика конфиденциальности</a>
                        <a href="/docs/offer.pdf" target="_blank" class="footer-offerta">Публичная офферта</a>
                    </div>
                    <div>
                        Дизайн сайта <b class="ps-2" style="font-family: 'Unbounded', sans-serif;">DKA-MEDIA</b>
                    </div>
                    <div>
                        2025 © TapWill
                    </div>
                </div>
            </div>
        </div>
    </footer>
    
    
</template>
<script>
export default{
    data(){
      return{
        userUrl: window.location.pathname
      }
    }
}
</script>

<style lang="scss">
.main-footer{
    padding-top: 100px;
    width: 100%;
    font-family: "Inter", sans-serif;

    h2{
        color: #FFF;
        font-size: 60px;
        font-weight: 600;
        text-align: start;
        font-family: 'Unbounded', sans-serif;
        margin-bottom: 50px;
        line-height: 1.1;
        letter-spacing: -0.02em;
        text-transform: uppercase;
    }

    p{
        font-size: 24px;
        color: #FFF;
        letter-spacing: -0.03em;
    }

    .footer-main-block{
        background: linear-gradient(90deg, #9c55d1 0%, #44157e 100%);
        border-radius: 60px;
        padding: 100px;
        color: #fff !important;

        .tapwillimg {
            border-right: 1px solid #fff;
            font-size: 30px;
            // font-family: 'Unbounded', sans-serif;
        }

        .footer-mini-text{
            color: #E3E3E3;
            font-size: 18px;
        }

        .footer-navigation{
            margin-top: 65px;
        }

        .footer-link{
            display: block;
            margin-bottom: 20px;
            font-size: 20px;
            color: #fff;
            text-decoration: none;
        }

        .footer-form{
            width: 100%;

            .footer-form-field{
                font-size: 20px;
                color: #FFF;
                padding: 12px;
                border-bottom: 1px solid #E3E3E3;
                width: 100%;

                input{
                    background: transparent;
                    outline: none;
                    border: none;
                    font-size: 20px;
                    color: #FFF;

                    &::placeholder{
                        color: #FFF;
                    }
                }
            }

            .btn-white{
                width: 100%;
                background: #FFF;
                border-radius: 60px;
                color: #521D89;
                font-size: 20px;
                padding-top: 26px;
                padding-bottom: 26px;
                margin-top: 15px;
            }
        }
    }

    .footer-bottom-block{
        padding-top: 30px;
        padding-bottom: 30px;

        div, a{
            color: #1E1E1E;
            display: block;
            text-decoration: none;
            font-size: 20px;
        }
    }
}

@media (max-width: 1280px){
.main-footer{
    h2{
        font-size: 40px;
        margin-bottom: 20px;
    }

    p{
        font-size: 16px;
    }

    .footer-main-block{
        padding: 50px;

        .footer-mini-text{
            font-size: 14px;
        }

        .footer-navigation{
            margin-top: 30px;
        }

        .footer-link{
            font-size: 16px;
        }
    }

    .footer-bottom-block{
        div, a{
            font-size: 16px;
        }
    }
}
}

@media (max-width: 996px){
    .main-footer{

        .footer-link{
            width: 100%;
        }

        .main-footer-navigation{
            order: 2 !important;
        }

        .main-footer-form{
            order: 1 !important;
            margin-bottom: 50px;
        }

        .footer-navigation-links div{
            width: 50%;
        }

        .footer-main-block .footer-mini-text{
            font-size: 16px;
            margin-top: 15px;
        }

        .footer-bottom-block{
            div{
                margin-left: 25px;
                margin-bottom: 25px;
            }

            .footer-offerta{
                margin-top: 25px;
            }
        }
    }
}   

@media (max-width: 576px){
    .main-footer{
        .footer-main-block{
            padding: 20px;
            border-radius: 15px;
            padding-top: 40px;
        }

        h2{
            font-size: 30px;
        }

        .footer-desc{
            font-size: 14px !important;
        }

        h2, .footer-desc{
            text-align: center;
        }

        .footer-form{
            .btn-white{
                font-size: 16px !important;
                padding-top: 18px !important;
                padding-bottom: 18px !important;
            }
        }

        .footer-navigation-links div{
            width: 100%;
        }

        .footer-form{
            input, label{
                font-size: 16px !important;
            }
        }

        .footer-bottom-block{
            div{
                margin-left: 10px;
                margin-bottom: 25px;
            }

            .footer-offerta{
                margin-top: 25px;
            }
        }
    }
}

</style>
