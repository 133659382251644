<template>
  <div class="container-fluid">

    <div class="alert alert-warning" role="alert">
      <p class="mb-0" style="font-size: 0.9em;">Нашли критическую ошибку в работе сервиса? Напишите на почту <a href="mailto:mail@tapwill.ru">mail@tapwill.ru</a> или в бот <a href="https://t.me/tapwill_support_bot">@tapwill_support_bot</a>. Мы подарим вам дополнительно 5 дней подписки. </p>
    </div>
      
    <div class="head-block row row-cols-1 row-cols-lg-2 justify-content-between">
  <div class="col content-title">
    <h1>География заказов и продаж</h1></div>
</div>
    <div class="content-block">
      <div class="accordion d-lg-none" id="filterOpen">
            <div class="accordion-item border-0">
                <h2 class="accordion-header">
                <button class="accordion-button collapsed ps-0" style="width: fit-content;" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-controls="collapseOne">
                   <span class="pe-3">Показать параметры</span>
                 </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#filterOpen">
                <div class="accordion-body">

                  <div class="pageFilter">
    <div class="js-pageFilter__form">
      <div class="row row-cols-lg-auto align-items-end">
        <div class="col-md js-pageFilter__daterangepicker mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
          <label  class="form-label">Период</label>
          <div class="input-group">
            <input type="text" class="form-control pageFilter__daterange js-daterangepicker" id="dashboard_period_mob" autocomplete="off"><span class="input-group-text"><label for="dashboard_period_mob"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"></path><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg></label></span></div>
          <input type="hidden" name="filter[start_period]" class="js-daterangepicker__start" value="14.02.2024">
          <input type="hidden" name="filter[end_period]" class="js-daterangepicker__end" value="14.03.2024">
        </div>
        <div class="col-md js-pageFilter__text mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
          <label for="article_92a2b5cb" class="form-label">Артикул продавца</label>
          <!-- <input type="text" class="form-control" v-model="filter.article" autocomplete="off" > -->
          <input class="form-control" list="datalistOptions" id="exampleDataList" placeholder="Введите артикул" v-model="filter.article">
            <datalist id="datalistOptions">
              <option v-for="item in article_list" :value="item[0]"/>
            </datalist>
        </div>
        
        <!-- <div class="col-md mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shopDashboard" class="form-label">Магазин</label>
                <select v-model="filter.stores" id="shopDashboard" class="form-select" >
                  <option value="">Не выбрано</option>
                  <option v-for="item in stores">{{ item.title }}</option>
                </select>
              </div> -->
              <div class="dropdown col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                  <label class="form-label">Магазин</label>
                  <button class="dropdown-select-button" data-bs-toggle="dropdown">
                    <span v-if="filter.stores.length == 0">Выберите магазины </span v-else><span>{{ filter.stores.join(', ') }}</span>&nbsp;&nbsp;&nbsp;
                  </button>

                  <ul class="dropdown-menu dropdown-select-list">
                    <li v-for="item in stores">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" :value="item.title" v-model="filter.stores" :id="`store_${item.title}`">
                        <label class="form-check-label" :for="`store_${item.title}`">
                          {{ item.title }}
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              <div class="col-md mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="brandDashboard" class="form-label">Бренд</label>
                <select v-model="filter.brands" id="brandDashboard" class="form-select" >
                  <option value="">Не выбрано</option>
                  <option v-for="item in brands">{{ item }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Маркетплейс</label>
                <select class="form-select" v-model="filter.marketplace" >
                  <option value="">Не выбрано</option>
                  <option>wildberries</option>
                  <option>ozon</option>
<option>yandex</option>
                </select>
              </div>
        <div class="col-md mb-3">
          <div class="btn-toolbar">
            <div class="btn-group me-2">
              <button class="btn btn-primary" @click="applyFilter">Применить</button>
            </div>
            <div class="btn-group"><a href="/geography" class="btn btn-outline-secondary">Сбросить</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>

                  </div>
                  </div>
                  </div>
                  </div>

  <div class="pageFilter d-lg-block d-none">
    <div class="js-pageFilter__form">
      <div class="row row-cols-lg-auto align-items-end">
        <div class="col-md js-pageFilter__daterangepicker mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
          <label  class="form-label">Период</label>
          <div class="input-group">
            <input type="text" class="form-control pageFilter__daterange js-daterangepicker" id="dashboard_period" autocomplete="off"><span class="input-group-text"><label for="dashboard_period"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"></path><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg></label></span></div>
          <input type="hidden" name="filter[start_period]" class="js-daterangepicker__start" value="14.02.2024">
          <input type="hidden" name="filter[end_period]" class="js-daterangepicker__end" value="14.03.2024">
        </div>
        <div class="col-md js-pageFilter__text mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
          <label for="article_92a2b5cb" class="form-label">Артикул продавца</label>
          <!-- <input type="text" class="form-control" v-model="filter.article" autocomplete="off" > -->
          <input class="form-control" list="datalistOptions" id="exampleDataList" placeholder="Введите артикул" v-model="filter.article">
            <datalist id="datalistOptions">
              <option v-for="item in article_list" :value="item[0]"/>
            </datalist>
        </div>
        
        <!-- <div class="col-md mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shopDashboard" class="form-label">Магазин</label>
                <select v-model="filter.stores" id="shopDashboard" class="form-select" >
                  <option value="">Не выбрано</option>
                  <option v-for="item in stores">{{ item.title }}</option>
                </select>
              </div> -->
              <div class="dropdown col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                  <label class="form-label">Магазин</label>
                  <button class="dropdown-select-button" data-bs-toggle="dropdown">
                    <span v-if="filter.stores.length == 0">Выберите магазины </span v-else><span>{{ filter.stores.join(', ') }}</span>&nbsp;&nbsp;&nbsp;
                  </button>

                  <ul class="dropdown-menu dropdown-select-list">
                    <li v-for="item in stores">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" :value="item.title" v-model="filter.stores" :id="`store_${item.title}`">
                        <label class="form-check-label" :for="`store_${item.title}`">
                          {{ item.title }}
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              <div class="col-md mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="brandDashboard" class="form-label">Бренд</label>
                <select v-model="filter.brands" id="brandDashboard" class="form-select" >
                  <option value="">Не выбрано</option>
                  <option v-for="item in brands">{{ item }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Маркетплейс</label>
                <select class="form-select" v-model="filter.marketplace" >
                  <option value="">Не выбрано</option>
                  <option>wildberries</option>
                  <option>ozon</option>
<option>yandex</option>
                </select>
              </div>
        <div class="col-md mb-3">
          <div class="btn-toolbar">
            <div class="btn-group me-2">
              <button class="btn btn-primary" @click="applyFilter">Применить</button>
            </div>
            <div class="btn-group"><a href="/geography" class="btn btn-outline-secondary">Сбросить</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 2">
            <p>У вас не добавлено ни одного магазина, загрузите свой первый магазин в разделе "<a href="/user-stores">Магазины</a>" </p>
          </div>

          <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 1">
            <p>У вас нет активной подписки, вы можете продлить подписку в разделе "<a href="/tariff">Подписка и платежи</a>" </p>
          </div>

<div v-if="$store.state.user_info.check == 0">

<div class="modal-loader d-flex justify-content-center" v-if="loading">
  <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
</div>
<div class="content-block geography" v-else>
    <nav class="nav nav-tabs mb-3 mt-5">
      <li class="nav-item">
        <a @click="tab = 'regions'" class="nav-link" :class="{'active': tab == 'regions'}">По регионам</a>
      </li>
      <li class="nav-item">
        <a @click="tab = 'warehouses'" class="nav-link" :class="{'active': tab == 'warehouses'}">По складам</a>
      </li>
    </nav>
  <div class="geography_content" v-if="tab == 'regions'">
    <div class="row mb-4">
      <div class="col-lg-12 col-xl-6 col-md-12 col-sm-12 mb-5">
        <p class="text-center mt-4 mb-2">Заказы (руб.)</p>
        <bar-chart :data="charData1" :colors="['#805dca']" prefix="₽" thousands=" "></bar-chart>
      </div>

      <div class="col-lg-12 col-xl-6 col-md-12 col-sm-12 mb-5">
        <p class="text-center mt-4 mb-2">Продажи (руб.)</p>
        <bar-chart :data="charData2" :colors="['#805dca']" prefix="₽" thousands=" "></bar-chart>
      </div>

      <div class="col-lg-12 col-xl-6 col-md-12 col-sm-12 mb-5">
        <p class="text-center mt-4 mb-2">Заказы (шт.)</p>
        <bar-chart :data="charData3" :colors="['#805dca']" thousands=" "></bar-chart>
      </div>

      <div class="col-lg-12 col-xl-6 col-md-12 col-sm-12 mb-5">
        <p class="text-center mt-4 mb-2">Продажи (шт.)</p>
        <bar-chart :data="charData4" :colors="['#805dca']" thousands=" "></bar-chart>
      </div>
    </div>
    
  </div>
  <div class="geography_content" v-else>
    <div class="row" v-for="item in warehousesInfo">
      <h5 class="mb-2">Склад отправки: {{ item[0] }}</h5>
      <div class="col-lg-12 col-xl-6 col-md-12 col-sm-12 mb-4">
        <h6 class="mb-2">Заказы по регионам (рубли)</h6>
        <table class="table table-sm table-responsive table-hover">
          <thead>
            <tr>
              <td>Регион заказа</td>
              <td>Сумма заказов, руб.</td>
              <td>Доля заказов по складу, %</td>
              <td>Доля заказов по всем, %</td>
            </tr>
          </thead>
          <tbody v-if="Object.keys(item[1]).includes('orders')">
            <tr v-for="item_data in Object.entries(item[1].orders)">
              <td>{{ item_data[0] }}</td>
              <td>{{ item_data[1]['sum'][0] }}</td>
              <td>{{ item_data[1]['sum'][1] }}</td>
              <td>{{ item_data[1]['sum'][2] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-12 col-xl-6 col-md-12 col-sm-12 mb-4">
        <h6>Продажи по регионам (рубли)</h6>
        <table class="table table-sm table-responsive table-hover">
          <thead>
            <tr>
              <td>Регион продажи</td>
              <td>Сумма продаж, руб.</td>
              <td>Доля продаж по складу, %</td>
              <td>Доля продаж по всем, %</td>
            </tr>
          </thead>
          <tbody v-if="Object.keys(item[1]).includes('sales')">
            <tr v-for="item_data in Object.entries(item[1].sales)">
              <td>{{ item_data[0] }}</td>
              <td>{{ item_data[1]['sum'][0] }}</td>
              <td>{{ item_data[1]['sum'][1] }}</td>
              <td>{{ item_data[1]['sum'][2] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-12 col-xl-6 col-md-12 col-sm-12 mb-5">
        <h6 class="mb-2">Заказы по регионам (штуки)</h6>
        <table class="table table-sm table-responsive table-hover">
          <thead>
            <tr>
              <td>Регион заказа</td>
              <td>Количество, шт.</td>
              <td>Доля заказов по складу, %</td>
              <td>Доля заказов по всем, %</td>
            </tr>
          </thead>
          <tbody v-if="Object.keys(item[1]).includes('orders')">
            <tr v-for="item_data in Object.entries(item[1].orders)">
              <td>{{ item_data[0] }}</td>
              <td>{{ item_data[1]['count'][0] }}</td>
              <td>{{ item_data[1]['count'][1] }}</td>
              <td>{{ item_data[1]['count'][2] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-12 col-xl-6 col-md-12 col-sm-12 mb-5">
        <h6>Продажи по регионам (штуки)</h6>
        <table class="table table-sm table-responsive table-hover">
          <thead>
            <tr>
              <td>Регион продажи</td>
              <td>Количество, шт.</td>
              <td>Доля продаж по складу, %</td>
              <td>Доля продаж по всем, %</td>
            </tr>
          </thead>
          <tbody v-if="Object.keys(item[1]).includes('sales')">
            <tr v-for="item_data in Object.entries(item[1].sales)">
              <td>{{ item_data[0] }}</td>
              <td>{{ item_data[1]['count'][0] }}</td>
              <td>{{ item_data[1]['count'][1] }}</td>
              <td>{{ item_data[1]['count'][2] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
  </div>
</div>
</div>
</template>

<script>
import axios from 'axios';
export default{
  data(){
    return{
      tab: 'regions',
      charData1: [],
      charData2: [],
      charData3: [],
      charData4: [],
      warehousesInfo: [],
      stores: [],
      brands: [],
      filter: {
        stores: [],
        brands: '',
        article: '',
        marketplace: ''
      },
      loading: true,
      article_list: []
    }
  },
  methods: {
    updateInfo(start, end){
      this.loading = true
      let post_data = {
        start_date: start,
        end_date: end,
        store: this.filter.stores,
        brand: this.filter.brands,
        article: this.filter.article,
        marketplace: this.filter.marketplace,
        user_id: this.$store.state.user_info.id
      }
      axios.post('https://tapwill.ru/api/get_geography.php', post_data).then((response)=>{
        console.log(response.data)
        this.charData1 = response.data[0];
        this.charData2 = response.data[2];
        this.charData3 = response.data[1];
        this.charData4 = response.data[3];
        this.warehousesInfo = Object.entries(response.data[4]);
        this.loading = false
      });
    },
    applyFilter(){
      this.updateInfo($('.js-daterangepicker').data('daterangepicker').startDate.format('YYYY-MM-DD'),  $('.js-daterangepicker').data('daterangepicker').endDate.format('YYYY-MM-DD'))
    },

    getArticles(){
        axios.post('https://tapwill.ru/api/get_articles.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
          this.article_list = response.data
          console.log(this.article_list)
        })
      }
  },
  computed: {
    result(){
        return this.$store.state.dashboard_data;
    },
    resultPeriod(){
      return this.$store.state.dashboardPeriod_data
    }
},
  created(){
    this.getArticles()
    this.updateInfo(moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
    axios.post('https://tapwill.ru/api/get_store.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
      this.stores = response.data
      console.log(this.stores)
    })
    axios.post('https://tapwill.ru/api/get_brands.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
      this.brands = response.data
      console.log(this.brands)
    })
  },
  mounted(){

    let start = moment().subtract(30, 'days');
    let end = moment();

    function cb(start, end) {
        $('.js-daterangepicker span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
    }

    $('.js-daterangepicker').daterangepicker({
      "locale": {
        "format": "DD.MM.YYYY",
        "separator": " - ",
        "applyLabel": "Сохранить",
        "cancelLabel": "Назад",
        "daysOfWeek": [
            "Вс",
            "Пн",
            "Вт",
            "Ср",
            "Чт",
            "Пт",
            "Сб"
        ],
        "monthNames": [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь"
        ],
        "firstDay": 1
    },
      
      opens: 'left',
      autoUpdateInput: true,
      startDate: start,
      endDate: end,
      ranges: {
           'Сегодня': [moment(), moment()],
           'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
           'Последние 7 дней': [moment().subtract(6, 'days'), moment()],
           'Последние 30 дней': [moment().subtract(29, 'days'), moment()],
           'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
           'Прошлый месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
      }, (start, end) => {
        $('.js-daterangepicker').data('daterangepicker').startDate = start
        $('.js-daterangepicker').data('daterangepicker').endDate = end
        this.updateInfo(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
      }, cb);


    cb(start, end);

    $('li[data-range-key="Custom Range"]').text('Произвольно')

  }
}
</script>

<style lang="scss">
// @import '../assets/style/geography.scss';
</style>