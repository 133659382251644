<template>
<div class="mainpage">
    
    <div class="fullver">
        <nav class="navbar navbar-expand-lg navbar-light fixed-top" style="background: #fff;">
            <div class="container">
                <div class="d-flex justify-content-between w-100 my-3 text-bg-light navbar-mobile">
                    <div class="d-flex">
                        <a class="navbar-brand fw-bold my-auto tapwillimg pe-2 pe-sm-3 me-0" href="/#">TapWill</a>
                        <div class="stats d-flex flex-column justify-content-start ps-2 ps-sm-3">
                            <p class="my-auto navbar-brand-text">Статистика и аналитика товаров <br> <span class = 'Wildberries'> Wildberries </span>, <span class = 'Ozon'> Ozon </span> и <span style="color: #1E1E1E;">Яндекс.Маркет</span> </p>
                        </div>
                    </div>
                    
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon d-flex"></span>
                    </button>
                </div>
                <div class="header_right ms-lg-auto ms-2">
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav flex-row my-3">
                        <!-- <li class="nav-item my-auto">
                        <a class="nav-link fw-semibold" href="#about_us">О сервисе</a>
                        </li>
                        <li class="nav-item my-auto">
                        <a class="nav-link fw-semibold" href="#tariff">Подписка</a>
                        </li>
                        <li class="nav-item my-auto">
                        <a class="nav-link fw-semibold" href="#faq">FAQ</a>
                        </li> -->
                        <li class="nav-item my-auto ">
                            <a class="nav-link fw-semibold d-flex" href="/auth">
                            <img src="../assets/images/main/iconamoon_enter-fill.png" alt="">
                            <span class="my-auto">Войти</span> 
                        </a>
                        </li>
                        <li class="nav-item my-auto">
                        <a class="nav-link btn-gradient d-flex" href="/reg" style="color: #fff;">Зарегистрироваться</a> 
                        </li>
                    </ul>
                </div>
              </div>
            </div>
        </nav>

    <div class="account-pages pt-2 py-5">
  <div class="container">
    <div class="success_page" v-if="successReg">
      <h3 class="text-dark-50 text-center mt-0 fw-bold">Спасибо за регистрацию в сервисе TapWill!</h3>
      <p class="text-muted text-center mb-4">Используйте данные, введенные при регистрации, для авторизации в личный кабинет.</p>
      <div class="w-100 d-flex">
        <a href="/auth" class="btn-gradient mx-auto">Войти в личный кабинет</a>
      </div>
      
    </div>
    <div class="row justify-content-center reg-form" v-else>

      <div class="col-xxl-6 col-lg-8">

        <div class="d-flex presentation-video mb-4">
          <video width="100%" height="auto" controls autoplay preload="auto" playsinline loop="true" muted class="mx-auto" style="border-radius: 30px;">
            <source src="https://test.tapwill.ru/api/video/presentation.MOV" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
        <div class="">
          <div class="main-block-2-block w-100">
            <div class="text-center m-auto">
              <h4 class="text-center mt-0 reg-form-title">Зарегистрироваться</h4>
              <p class="mb-4">Зарегистрируйтесь и получите бесплатный доступ ко всем функциям TAPWILL на 14 дней!</p>
            </div>
            <form id="signUp" @submit.prevent="sendForm">
              <div class="mb-3">
                <label for="fullname" class="form-label">ФИО</label>
                <input class="form-control" v-model="form_data.name" type="text" id="fullname" name="fullname" placeholder="Иванов Иван Иванович" autocomplete="off" minlength="2" maxlength="100" required >
              </div>
              <div class="mb-3">
                <label for="phone" class="form-label">Номер телефона</label>
                <input type="text" v-model="form_data.phone" class="form-control" id="phone" name="phone" placeholder="+7(999)999-99-99" autocomplete="off" required>
              </div>
              <div class="mb-3">
                <label for="emailaddress" class="form-label">Email</label>
                <input class="form-control" v-model="form_data.mail" type="email" id="emailaddress" name="email" placeholder="ivanov@yandex.ru" autocomplete="off" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Пароль</label>
                <div class="input-group input-group-merge">
                  <input type="password" v-model="form_data.password" id="password" name="password" class="form-control" placeholder="Придумайте пароль" minlength="5" maxlength="30" autocomplete="off">
                  
                </div>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Каким способом менеджеру связаться с вами для помощи в настройке сервиса?</label>
                <div class="input-group input-group-merge">
                  <input type="text" v-model="form_data.contact" id="contact" name="contact" class="form-control" placeholder="Укажите ник в telegram или номер телефона" autocomplete="off">
                </div>
              </div>
              <div class="mb-3">
                <div class="form-check d-flex form-switch ms-2 mt-5">
                  <input type="checkbox" class="form-check-input my-auto" id="checkbox-signup" checked required>
                  <label class="mb-0 mt-0 ms-3" style="font-weight: 400;" for="checkbox-signup">Я согласен со всеми условиями <a href="/docs/rules" target="_blank">пользовательского соглашения</a> и <a href="/docs/privacy" target="_blank">политики конфиденциальности</a></label>
                </div>
              </div>
              <div class="ms-2 my-5">
                <YandexSmartCaptcha 
                  siteKey="ysc1_cQKG8lGlsECvdbCuro4YNa8fPLQ7XS57V6RNVllt5e3a6f97"
                  @onSuccess="recaptchaVerified"
                />
              </div>
              
              <!--<vue-recaptcha v-show="showRecaptcha" sitekey="6Lfj4W0qAAAAAPWRc3JkCWvN4zQitNg2g8FtZCWo"
		 size="normal" 
		 theme="light"
		 hl="tr"
		 :loading-timeout="loadingTimeout"
		 @verify="recaptchaVerified"
		 @expire="recaptchaExpired"
		 @fail="recaptchaFailed"
		 @error="recaptchaError"
		 ref="vueRecaptcha"/>-->
              <div class="my-3 text-center d-grid gap-2">
                <button class="btn-gradient js-submitRegistration" type="submit"> Получить бесплатный доступ
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 text-center">
            <p style="font-weight: 600;">
              Уже есть аккаунт? <a href="/auth" class="ms-1" style="color: #8C43CF; text-decoration: none;">Войти</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
</div>

</template>

<style lang="scss" scoped>
    @import '../assets/style/mainpagenew.scss';
  </style>

<script>
import axios from 'axios';
import { YandexSmartCaptcha } from '@gladesinger/vue3-yandex-smartcaptcha'

export default{
  components: {
    YandexSmartCaptcha
  },
  data(){
    return{
      successCaptcha: false,
      successReg: false,
      form_data: {
        name: '',
        phone: '',
        mail: '',
        password: '',
        contact: ''
      }
      
    }
  },

  methods: {
    recaptchaVerified(response) {
      if(response){
        this.successCaptcha = true
      }
      console.log(response)
	},
	recaptchaExpired() {
	  this.$refs.vueRecaptcha.reset();
	},
	recaptchaFailed() {
	},
	recaptchaError(reason) {
	},
    sendForm(){
      if(this.successCaptcha){
      axios.post('https://test.tapwill.ru/api/reg.php', this.form_data).then((response)=>{
        if(response.status == 200){
          this.successReg = true
          if(response.data !== 0){
            let mess = `\*Ура, у нас новая регистрация!\*\n${this.form_data.name}\n${this.form_data.phone}\n${this.form_data.mail}\nКонтакт: ${this.form_data.contact}`;
            sendTGMessage(mess)
            function sendTGMessage(message){
              const telegramBotToken = '7243563889:AAHny5XrJT5HFWd7EYzf94p1QBBgzGuhBDI';
              const chatId = '-1002145025576';
              let data = {
                  chat_id: chatId,
                  text: message
              }
              axios.post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, data)
              var _tmr = window._tmr || (window._tmr = []);
              _tmr.push({"type":"reachGoal","id":3577648,"goal":"registration"});
            }
          }
           else{
            alert('Пользователь с таким E-mail или номером телефона уже существует')
            console.log(response)
          }
        }
        else{
          alert('Произошла ошибка! Попробуйте позднее')
        }
      })
    }
    else{
      alert('Подтвердите что вы не робот')
    }
    }
  },

  mounted(){
    IMask(document.getElementById('phone'), {
            mask: '+{7}(000)000-00-00'
        });
  }
}
</script>