<template>
    <div class="container-fluid">

      <div class="alert alert-warning" role="alert">
        <p class="mb-0" style="font-size: 0.9em;">Нашли критическую ошибку в работе сервиса? Напишите на почту <a href="mailto:mail@tapwill.ru">mail@tapwill.ru</a> или в бот <a href="https://t.me/tapwill_support_bot">@tapwill_support_bot</a>. Мы подарим вам дополнительно 5 дней подписки. </p>
      </div>
      
      <div class="head-block row row-cols-1 row-cols-lg-2 justify-content-between">
        <div class="col content-title">
          <h1>Как пользоваться сервисом TapWill</h1>
          <p>test.tapwill.ru - сервис внутренней аналитики для бизнеса на маркетплейсах. Он автоматически загружает всю статистику по заказам и продажам, финансовые отчеты, операции и расходы. Вы можете видеть прибыль по всем магазинам и маркетплейсам в одном месте, не тратить время на сбор данных из нескольких кабинетов или маркетплейсов и добавлять любое количество магазинов. TapWill создан селлерам для селлеров. Наш опыт в товарном бизнесе и разработке IT систем позволил создать полезный и удобный продукт, поэтому он подойдет как для новичков, так и для опытных селлеров.
            <br> Эта инструкция научит вас пользоваться всем функционалом сервиса и ответит на популярные вопросы. 
          </p>
        </div>
      </div>

      <div class="d-flex presentation-video">
        <video width="100%" height="auto" controls autoplay preload="auto" playsinline loop="true" muted class="mx-auto">
          <source src="https://test.tapwill.ru/api/video/presentation.MOV" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>

      

      <div class="mt-5 how_to_use">
        <h2 class="mb-4">Как подключить магазин</h2>
        <nav class="nav nav-tabs" style="width: fit-content;">
            <li class="nav-item">
                <a @click="apiKeysCreate = 0" class="nav-link" :class="{'active': apiKeysCreate == 0}"><img src="../assets/images/wildberries.svg" style="height: 20px;" alt=""></a>
            </li>
            <li class="nav-item">
                <a @click="apiKeysCreate = 1" class="nav-link"  :class="{'active': apiKeysCreate == 1}"><img src="../assets/images/ozon.svg" style="height: 20px;" alt=""></a>
            </li>
        </nav>

        <div class="mt-3" v-if="apiKeysCreate == 0">
            <p class="mt-4">1. Создание токена (ключа подключения)</p>
            <p>Зайдите в раздел настройки в Кабинете селлера на Wildberries. Перейдите во вкладку Доступ к API (или <a href="https://seller.wildberries.ru/supplier-settings/access-to-api">нажмите сюда</a>). </p>
            <img src="../assets/images/about_us/img1.png" alt="">

            <p class="mt-4">2. Нажмите кнопку Создать новый токен.</p>
            <img src="../assets/images/about_us/img2.png" alt="">

            <p class="mt-4">3. В открывшемся окне введите имя нового токена и выберите все методы API. Важно отметить галочкой пункт «Только на чтение». И нажмите кнопку скопировать токен. </p>
            <p><i>Важно: Созданный токен не позволяет нам вносить изменения в ваш магазин, а только позволяет собирать и обрабатывать статистику.</i></p>
            <img src="../assets/images/about_us/img3.png" alt="">

            <p class="mt-4">4. Скопируйте токен</p>
            <img src="../assets/images/about_us/img4.png" alt="">

            <p class="mt-4">5. Нажмите кнопку “Добавить магазин” и выберете “Wildberries” в разделе "Магазины" личного кабинета Tapwill</p>

            <p class="mt-4">6. Вставьте токен в поле Ключ API WB на сайте.</p>
            <img src="../assets/images/about_us/img5.png" alt="">
        </div>

        <div class="mt-3"  v-if="apiKeysCreate == 1">
            <p class="mt-4">1. Зайди в настройки в Кабинете селлера Ozon в раздел API ключи (или <a href="https://seller.ozon.ru/app/settings/api-keys?currentTab=sellerApi">нажмите сюда</a>) </p>

            <p class="mt-4">2. В разделе Seller API (указатель 1) скопируйте Client ID (указатель 2) и вставьте в поле Client Id API Ozon.</p>
            <img src="../assets/images/about_us/img6.png" alt="">

            <p class="mt-4">3. Нажмите сгенерировать ключ</p>
            <img src="../assets/images/about_us/img7.png" alt="">

            <p class="mt-4">4. Введите название токена (указатель 1) и в Типы токена выберете Admin real only (указатель 2)</p>
            <img src="../assets/images/about_us/img8.png" alt="">

            <p class="mt-4">5. Скопируйте получившийся ключ и вставьте в поле Ключ API Ozon на сайте.</p>
            <img src="../assets/images/about_us/img9.png" alt="">

            <p class="mt-4">6. Перейдите в меню Performance API (указатель 1) и нажмите Создать аккаунт (указатель 2)</p>
            <img src="../assets/images/about_us/img10.png" alt="">

            <p class="mt-4">7. Нажмите на созданный аккаунт</p>
            <img src="../assets/images/about_us/img11.png" alt="">

            <p class="mt-4">8. Нажмите на Добавить ключ</p>
            <img src="../assets/images/about_us/img12.png" alt="">

            <p class="mt-4">9. Нажмите Добавить </p>
            <img src="../assets/images/about_us/img13.png" alt="">

            <p class="mt-4">10. Нажмите кнопку “Добавить магазин” и выберете Ozon в разделе "Магазины" личного кабинета Tapwill</p>

            <p class="mt-4">11.	Скопируйте Client Id (указатель 1) и вставьте в поле Performance Client Id API Ozon на сайте. Скопируйте Client Secret (указатель 2) и вставьте в поле Performance Client Secret API Ozon</p>
            <img src="../assets/images/about_us/img14.png" alt="">
        </div>
      </div>
    </div>
</template>

<script>
export default{
    data(){
        return{
            apiKeysCreate: 0
        }
    }
}
</script>